import React, { Component } from 'react'
import Link from '../link'
import NavItem from '../nav-item'
import logo from '../../images/sacn.png'
import MenuOpenerIcon from '../../images/menu-opener.inline.svg'
import SlideoutMenu from '../slideout-menu'
import { headerNavigation } from '../../lib/navigation'

class Header extends Component {
  static defaultProps = {
    openClass: 'menu-open',
  }

  constructor(props) {
    super(props)

    this.state = {
      slideoutOpen: false,
      activeItem: null,
    }
  }

  componentDidUpdate() {
    const action = this.state.slideoutOpen ? 'add' : 'remove'

    document.body.classList[action](this.props.openClass)
  }

  openSlideout(item) {
    this.setState({
      slideoutOpen: !this.state.slideoutOpen,
      activeItem: item?.props?.id,
    })
  }

  closeSlideout() {
    this.setState({
      slideoutOpen: false,
      activeItem: null,
    })
  }

  navigateSlideout(id) {
    this.setState({
      activeItem: id === this.state.activeItem ? null : id,
    })
  }

  render() {
    const { navItems } = this.props

    return (
      <>
        <nav className="header__wrapper">
          <div className="header">
            <div className="header__container">
              <Link href="/" className="header__brand">
                <img src={logo} />
              </Link>

              <div className="header__right">
                <div className="d-none d-md-flex align-items-center">
                  {navItems.length > 0 ? (
                    <ul className="navbar-nav desktop-nav">
                      {navItems.map(({ __typename, ...item }) => (
                        <NavItem onClick={this.openSlideout.bind(this)} key={item.id} type={__typename} {...item} />
                      ))}
                    </ul>
                  ) : ''}
                </div>

                <button
                  type="button"
                  className="header__toggle d-flex d-md-none"
                  aria-controls="primary-navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={this.openSlideout.bind(this, null)}
                >
                  <MenuOpenerIcon className="header__toggle-icon" />
                </button>
              </div>
            </div>
          </div>
        </nav>

        <SlideoutMenu
          navigate={this.navigateSlideout.bind(this)}
          activeItem={this.state.activeItem}
          show={this.state.slideoutOpen}
          onClose={this.closeSlideout.bind(this)}
        />
      </>
    )
  }
}

export default () => <Header navItems={headerNavigation()} />

